import http from './http-common'

export async function getCategories (filters) {
  return await http.get(`/staff/productseries?sort=createdDate,desc&name=${filters.name}&start=${filters.start}&end=${filters.end}&size=${filters.size}&page=${filters.page}`)
}

export async function getCategory (id) {
  return await http.get(`/staff/productseries/${id}`)
}

export async function createCategory (data) {
  return await http({
    method: 'POST',
    url: '/staff/productseries',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()
    }
  })
}

export async function updateCategory (id, data) {
  return await http({
    method: 'PUT',
    url: `/staff/productseries/${id}`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data;boundary = ' + new Date().getTime()
    }
  })
}

export async function deactivateCategory (id) {
  return await http.put(`/staff/productseries/${id}/deactivate`)
}

export async function activateCategory (id) {
  return await http.put(`/staff/productseries/${id}/activate`)
}

export async function deleteCategory (id) {
  return await http.delete(`/staff/productseries/${id}`)
}

export async function updateSortingOrderForProductSeries (json) {
  return (await http.post('/staff/products/sorting-order-for-product-series', json)).data
}
